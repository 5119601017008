.background {
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.main-container {
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.header-bar {
  background: var(--header-bg);
  color: var(--header-color);
  padding: 5px 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid #d1d1d1;
  top: 0;
  z-index: 1100;
  backdrop-filter: blur(10px);
}

.header-logo {
  font-family: 'Roboto', sans-serif;
  font-weight: bold;
  margin-right: 20px;
  font-size: 1.2rem;
}

.logo-section {
  width: 20%;
  display: flex;
  align-items: left;
}

.logo-image {
  height: 40px;
  margin-right: 20px;
}

.google-ads-container {
  width: 100%;
  text-align: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.9);
  padding-top: 10px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
  flex-shrink: 0; /* Ensure the ads container does not shrink */
}

.adsbygoogle {
  display: block;
  width: 100%;
  height: auto;
}

.info-section {
  width: 80%;
  display: flex;
  justify-content: right;
}

.info-item {
  display: flex;
  align-items: center;
  margin-right: 20px;
}

.info-text {
  margin-left: 6px !important;
  margin-right: 2px !important;
}

.ip-info-container {
  text-align: left;
  border-radius: 5px;
  background: rgba(255, 255, 255, 0.7); /* Slight transparency for glassy effect */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(10px); /* Add a blur effect for glassy finish */
}

.ip-info-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0;
  padding: 7px 30px 7px 10px;
  text-align: left;
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 0.7);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  transition: background-color 0.3s ease;
}

.ip-info-box:hover {
  background-color: #f0f0f0;
}

.copy-button {
  margin-left: 10px;
  cursor: pointer;
  color: #007bff;
}

.more-info-toggle {
  margin-top: 20px;
  cursor: pointer;
  color: #007bff;
  font-weight: bold;
  text-decoration: underline;
}

.more-info-button {
  margin-top: 20px;
  width: 100%; /* Full-width button */
}

.more-info-content {
  display: none;
  margin-top: 20px;
  text-align: left;
}

.more-info-content.visible {
  display: block;
}

@keyframes slideDown {
  from {
    max-height: 0;
    opacity: 0;
  }
  to {
    max-height: 00px;
    opacity: 1;
  }
}

.MuiPaper-root {
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  background: rgba(255, 255, 255, 0.9);
}

.privacy-button {
  position: fixed !important;
  bottom: 10px;
  right: 10px;
  z-index: 1000;
  pointer-events: auto;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.overlay-content {
  background: rgba(255, 255, 255, 0.9);
  padding: 20px;
  border-radius: 8px;
  width: 90%; /* Adjust width for responsiveness */
  max-width: 600px;
  max-height: 80vh; /* Ensure it fits within the viewport */
  overflow-y: auto; /* Make the content scrollable if necessary */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}

.copied-message {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
  background-color: rgba(255, 255, 255, 0.85);
  color: #080808;
  padding: 5px 10px;
  border-radius: 4px;
  border: 1px solid;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  font-size: 0.8rem;
  z-index: 1000;
}

.copy-button-container {
  position: relative;
  display: inline-block;
}

:root {
  --header-bg: #ffffff;
  --header-color: #000000;
}

[data-theme="dark"] {
  --header-bg: rgba(0, 0, 0, 0.7);
  --header-color: #ffffff;
}

[data-theme="light"] {
  --header-bg: rgba(255, 255, 255, 0.7);
  --header-color: #000000;
}

/* Dark theme adjustments */
[data-theme="dark"] .ip-info-container {
  background-color: rgba(51, 51, 51, 0.7);
  color: #ffffff;
}

[data-theme="dark"] .overlay-content {
  background: #1d1d1d;
}

[data-theme="dark"] .ip-info-box {
  background-color: rgba(39, 37, 37, 0.7);
  color: #ffffff;
}

[data-theme="dark"] .ip-info-box:hover {
  background-color: #1e1c1c;
}

[data-theme="dark"] .google-ads-container {
  background-color: rgba(51, 51, 51, 0.7);
}


/* Media Queries for Different Devices */
@media (max-width: 600px) {
  .overlay-content {
    width: 90%; /* Adjust width for small screens */
    height: 80vh; /* Ensure it fits within the viewport */
  }
}

@media (max-width: 720px) {
  .header-bar {
    font-size: 0.8rem;
  }
  .ip-info-container {
    padding: 10px;
  }
}

@media (min-width: 721px) and (max-width: 1280px) {
  .header-bar {
    font-size: 1rem;
  }
  .ip-info-container {
    padding: 15px;
  }
}

@media (min-width: 1281px) {
  .header-bar {
    font-size: 1.2rem;
  }
  .ip-info-container {
    padding: 20px;
  }
}

